import * as d3 from 'd3';
import drawPie from './drawPie';
import tooltip from './tooltip';

export default function (input: Charts.Pie, innerRadius?: number, outerRadius?: number, image?: { width: number, height: number, radiusFactor: number }) {
  const margin = input.margin || { top: 30, right: 0, bottom: 0, left: 0 };

  const svg = d3.select(input.container).append("svg");
  svg
    .attr('class', 'chart')
    .attr('width', input.size.width)
    .attr('height', input.size.height)
    .attr('viewBox', `0 0 ${input.size.width} ${input.size.height}`);

  if (input.title)
    svg.append('text')
      .attr('class', 'title')
      .attr('transform', `translate(${input.size.width / 2}, 0)`)
      .attr('text-anchor', 'middle')
      .attr('dy', '1em')
      .text(input.title);

  const domain = input.serie1.domain || Array.from(new Set(input.serie1.data.map(e => e[0])));
  const color = d3.scaleOrdinal<string>()
    .domain(domain)
    .range(d3.quantize(t => d3.interpolateSpectral(t * 0.8 + 0.1), domain.length).reverse());

  const availableWidth = input.size.width - margin.left - margin.right;
  const availableHeight = input.size.height - margin.top - margin.bottom;
  const x = margin.left + availableWidth / 2;
  const y = margin.top + availableHeight / 2;

  if (!innerRadius) {
    let radius = .5 * Math.min(availableWidth, availableHeight);

    if (image)
      radius -= Math.max(image.width, image.height);

    innerRadius = radius * .8;
    outerRadius = radius;
  }

  const chartTooltip = tooltip();
  const chart = svg.node();
  const mouseover = (data, x, y) => chartTooltip.mouseover([{ text: data[0] }], x, y)
  drawPie(svg, color, input, mouseover, chartTooltip.mouseout)(x, y, innerRadius, outerRadius, image);
  chart.appendChild(chartTooltip.element.node());
};