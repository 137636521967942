import bloc from './bloc';
import entry from './entry';

export default function (container: HTMLElement, title: string, iconname: string, entries?: Array<[string, string | number, string, string]>) {
  const content = bloc(container, title, 'main');

  const row = document.createElement('div');
  row.className = 'row';

  const div = document.createElement('div');
  const icon = document.createElement('div');
  icon.className = 'icon ' + iconname;
  div.appendChild(icon);
  row.appendChild(div);

  const figures = document.createElement('div');
  entries.forEach(function (e) {
    entry(figures, ...e);
  });
  row.appendChild(figures);

  content.appendChild(row);

  return content;
};