import * as d3 from 'd3';

export default function (): { element: Charts.d3Selection, mouseover: Charts.MouseCallback, mouseout: Charts.MouseCallback } {
  const element = d3.select(document.createElementNS(d3.namespaces.svg, 'g'));
  element.attr('class', 'tooltip');
  element.style('opacity', 0);
  element.append('rect')
    .attr('fill', 'white')
    .attr('stroke', '#999')
    .attr('rx', 5);
  const textElement = element.append('text')
    .attr('fill', 'black')
    .attr('dy', -10)
    .attr('text-anchor', 'middle');

  const mouseover: Charts.MouseCallback = function (text, x, y) {
    element.attr('transform', `translate(${x}, ${y})`)
      .transition()
      .duration(100)
      .style('opacity', .9);
    textElement.selectAll('tspan').remove();
    text.forEach(function (part) {
      const partElement = textElement.append('tspan')
        .text(part.text);
      if (part.attributes)
        part.attributes.forEach(function ([attribute, value]) {
          partElement.attr(attribute, value);
        })
    });

    const bbox = (textElement.node() as SVGTextElement).getBBox();
    element.select('rect')
      .attr('x', bbox.x - 10)
      .attr('y', bbox.y - 5)
      .attr('width', bbox.width + 20)
      .attr('height', bbox.height + 10);
  };

  const mouseout: Charts.MouseCallback = function (text, x, y) {
    element.transition()
      .duration(500)
      .style('opacity', 0)
  };

  return { element, mouseover, mouseout };
};