/// <reference path="../types/home.ts"/>

import more from '../more';
import blocWithEntries from './blocWithEntries';

export default function (container: HTMLElement, sector: Home.Sector) {
  const entries: Array<[string, string | number, string, string]> = [
    [null, 3, null, 'départements côtiers'],
    [null, 5, null, 'quartiers maritimes'],
    [null, 32, null, 'ports']
  ];
  const activities = blocWithEntries(container, 'La Pêche Normande en Cartes', 'maps', entries);
  more(activities, '/cartes');
}