export default function (
  data: { [section: string]: Home.Species | Home.Environment | Home.Sector | Home.Activities | Home.Maps },
  sections: { [section: string]: (container: HTMLElement, data: (Home.Species | Home.Environment | Home.Sector | Home.Activities | Home.Maps)) => void },
  subhome?: string
) {
  const disabledSizeRatio = .7;
  let container: HTMLElement, bubbles: Array<HTMLElement>, activeBubble: HTMLElement, text: HTMLElement;
  let containerHeight: number, windowWidth: number, bubbleSize: number, bubbleMargin: number, bubblesRowHeight: number, bubblesHeightMargin: number;

  const left = function (order: number, disabled?: boolean): number {
    return disabled ?
      (windowWidth - 4 * bubbleSize * disabledSizeRatio - 5 * bubbleMargin) / 2 + order * (2 * bubbleMargin + bubbleSize * disabledSizeRatio) :
      order < 3 ?
        (windowWidth - 3 * bubbleSize - 4 * bubbleMargin) / 2 + order * (2 * bubbleMargin + bubbleSize) :
        (windowWidth - 2 * bubbleSize - 2 * bubbleMargin) / 2 + (order - 3) * (2 * bubbleMargin + bubbleSize)
  };

  const bottom = function (order: number): number {
    return order < 3 ?
      bubblesHeightMargin + bubblesRowHeight :
      bubblesHeightMargin;
  };

  const size = function () {
    windowWidth = window.innerWidth;
    bubbleMargin = .01 * windowWidth;
    containerHeight = container.clientHeight;
    bubbleSize = Math.min(175, .25 * windowWidth);
  };

  const resetBubble = function (bubble: HTMLElement, bubbleSize: number) {
    const content = <HTMLElement>bubble.querySelector('.content');
    const background = <HTMLElement>bubble.querySelector('.bubbleBackground');
    const h2 = <HTMLElement>bubble.querySelector('h2');

    bubble.classList.remove('active');
    h2.style.removeProperty('font-size');
    bubble.style.width = bubble.style.height = background.style.width = background.style.height = bubbleSize + 'px';
    content.style.opacity = '0';
    content.style.height = '0px';
    content.style.width = '0px';
    content.style.marginBottom = '0px';
    background.style.opacity = '1';
    background.style.borderWidth = '5px';
  };

  const reset = function (bubbleSize: number) {
    for (let i = 0; i < bubbles.length; i++) {
      const bubble = bubbles[i] as HTMLElement;

      resetBubble(bubble, bubbleSize);
      bubble.style.left = left(i) + 'px';
      bubble.style.bottom = bottom(i) + 'px';
      bubble.style.borderRadius = '50%';
      bubble.style.borderWidth = '5px';
      bubble.style.opacity = '1';
    };
  };

  const minimizeBubble = function (bubble: HTMLElement, order: number) {
    const background = <HTMLElement>bubble.querySelector('.bubbleBackground');
    const h2 = <HTMLElement>bubble.querySelector('h2');

    resetBubble(bubble, bubbleSize * disabledSizeRatio);
    h2.style.fontSize = '.8em';
    bubble.style.zIndex = '1';
    bubble.style.width = bubble.style.height = background.style.width = background.style.height = bubbleSize * disabledSizeRatio + 'px';
    bubble.style.left = left(order, true) + 'px';
    bubble.style.bottom = bubbleMargin + 'px';
  }

  const activate = function (id: string) {
    text.style.display = 'none';
    text.style.opacity = '0';

    const bubble = bubbles.find(e => e.id === id);
    const background = <HTMLElement>bubble.querySelector('.bubbleBackground');
    const content = <HTMLElement>bubble.querySelector('.content');
    const h2 = <HTMLElement>bubble.querySelector('h2');

    if (sections[id]) {
      sections[id](content, data[id]);
      delete sections[id];
    }
    activeBubble = bubble;

    bubble.classList.add('active');
    h2.style.removeProperty('font-size');
    content.style.opacity = '1';
    content.style.height = '100%';
    content.style.width = '100%';
    content.style.marginBottom = disabledSizeRatio * bubbleSize + 2 * bubbleMargin + 'px';
    background.style.borderWidth = '0';
    background.style.opacity = '0';
    bubble.style.zIndex = '0';
    bubble.style.left = '0px';
    bubble.style.bottom = '0px';
    bubble.style.width = bubble.style.height = '100%';
    background.style.width = background.style.height = bubbleSize + 'px';
  };

  const state = function (subhome: string) {
    let disabledOrder = 0;
    for (let i = 0; i < 5; i++) {
      const bubble = <HTMLElement>bubbles[i];

      if (bubble.id !== subhome)
        minimizeBubble(bubble, disabledOrder++);
      else
        activate(subhome);
    };
  };

  const transition = function (event: Event) {
    let target = <HTMLElement>event.target;

    while (target && !target.classList.contains('bubble'))
      target = target.parentElement;

    if (target === null)
      return;

    if (activeBubble && (<HTMLElement>event.target).tagName === 'H2' && target === activeBubble) {
      window.history.pushState(null, '', '/home');

      activeBubble.addEventListener("transitionend", function(event) {
        text.style.removeProperty('display');
        setTimeout(() => text.style.opacity = '1', 0);
      }, {once: true});

      activeBubble = null;
      reset(bubbleSize);
    }
    else {
      window.history.pushState(target.id, '', '/home/' + data[target.id].name);
      state(target.id);
    }
  };

  const placeBubbles = function (subhomeNavigation?: string) {
    if (!container) {
      text = document.querySelector('#content .text');
      container = <HTMLElement>document.querySelector('.bubbles');
      bubbles = Array.from(container.querySelectorAll('.bubble'));
      container.addEventListener('click', transition);
    }

    size();
    bubblesRowHeight = bubbleMargin + bubbleSize;
    bubblesHeightMargin = (containerHeight - 2 * bubblesRowHeight) / 2;

    reset(bubbleSize);

    if (subhomeNavigation)
      state(subhomeNavigation);
    else
      if (subhome) {
        const id = Object.keys(data).find(id => data[id].name === subhome);
        window.history.pushState(id, '', '/home/' + data[id].name);
        setTimeout(() => state(id), 1000);
      }
  };

  if (document.readyState === 'loading')
    window.addEventListener('load', () => placeBubbles(), { once: true });
  else
    placeBubbles()
  window.addEventListener('resize', () => placeBubbles());
  window.addEventListener('popstate', function (event) {
    placeBubbles(event.state);
  });
};