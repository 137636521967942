export default function (container: HTMLElement, key: string, value: number | string, pretext: string, posttext: string) {
  const div = document.createElement('div');
  div.className = key;
  let span;
  if (pretext) {
    span = document.createElement('span');
    span.appendChild(document.createTextNode(pretext));
    div.appendChild(span);
  }

  if (value) {
    const text = value + '';
    span = document.createElement('span');
    span.appendChild(document.createTextNode(text));
    span.className = 'value';
    div.appendChild(span);
  }

  span = document.createElement('span');
  span.appendChild(document.createTextNode(posttext));
  div.appendChild(span);

  container.appendChild(div);
};