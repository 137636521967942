import init from './home/animation';
import species from './home/species';
import sector from './home/sector';
import activities from './home/activities';
import maps from './home/maps';
import environment from './home/environment';

(<any>window).build = async function (subhome?: string) {
  const response = await fetch('/api/home', {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  });

  if (!response.ok)
    return;

  const json = await response.json();

  const data: { [section: string]: Home.Species | Home.Environment | Home.Sector | Home.Activities | Home.Maps } = {
    species: {
      name: 'espèces',
      ...json.species
    },
    environment: {
      name: 'environnement',
      ...json.environment
    },
    maps: { name: 'cartographie' },
    activities: {
      name: 'activités',
      ...json.activités
    },
    sector: {
      name: 'filière',
      ...json.filière
    },
  };

  init(data, { species, sector, activities, maps, environment }, subhome);
};