/// <reference path="../types/home.ts"/>

import more from '../more';
import blocWithEntries from './blocWithEntries';

export default function (container: HTMLElement, data: Home.Environment) {
  let entries: Array<[string, string | number, string, string]> = [['côtes', data.côtes, null, 'km de Côtes'],
  ['surface', data.surface, null, 'km² de Surface maritime'],
  ['profondeur', data.profondeur, 'Profondeur', ''],
  ['fonds', data.fonds, 'Fonds', ''],
  ['température', data.température, 'Température', '']];
  const environment = blocWithEntries(container, 'Données Environnementales', 'seawave', entries);

  more(environment, '/environnement/donnéesenvironnementales');

  entries = [
    [null, null, 'Aires marines protégées,', ''],
    [null, null, 'Energies Marines Renouvelables,', ''],
    [null, null, 'Extraction de granulats, ...', '']
  ];
  const activities = blocWithEntries(container, 'Autres Activités et Usages', 'windfarm', entries);
  more(activities, '/environnement/activités');
};