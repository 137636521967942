/// <reference path="../types/home.ts"/>

import more from '../more';
import bloc from './bloc';

const entry = function (container: HTMLElement, key: keyof Home.Activities, sector: Home.Activities, pretext: string, posttext: string) {
  const value = sector[key] + '';

  const div = document.createElement('div');
  div.className = key;
  let span;
  if (pretext) {
    span = document.createElement('span');
    span.appendChild(document.createTextNode(pretext));
    div.appendChild(span);
  }

  span = document.createElement('span');
  span.appendChild(document.createTextNode(value));
  span.className = 'value';
  div.appendChild(span);

  span = document.createElement('span');
  span.appendChild(document.createTextNode(posttext));
  div.appendChild(span);

  container.appendChild(div);
};

const mainEntities = function(title: string, items: Array<string>) {
  let div = document.createElement('div');

  div.className = 'items';
  let titleDiv = document.createElement('div');
  titleDiv.className = 'title';
  titleDiv.appendChild(document.createTextNode(title));
  div.appendChild(titleDiv);

  items.forEach(function (gear) {
    const span = document.createElement('span');
    span.className = 'item';
    span.appendChild(document.createTextNode(gear));
    div.appendChild(span);
  });

  return div;
}

export default function (container: HTMLElement, sector: Home.Activities) {
  const mainOnboard = bloc(container, 'Pêche Embarquée', 'main');
  let icon = document.createElement('div');
  icon.className = 'icon boat';
  mainOnboard.appendChild(icon);
  entry(mainOnboard, 'navires', sector, null, 'navires');
  entry(mainOnboard, 'taillemoyenne', sector, 'de longueur moyenne', 'm');
  mainOnboard.appendChild(mainEntities('Engins principaux :', sector.principauxengins));
  more(mainOnboard, '/activité/peche-embarquee');

  const mainFoot = bloc(container, 'Pêche à Pied', 'main');
  icon = document.createElement('div');
  icon.className = 'icon sailor';
  mainFoot.appendChild(icon);
  entry(mainFoot, 'pap', sector, null, 'pêcheurs à pied');
  mainFoot.appendChild(mainEntities('Espèce principale :', sector.principalesespeces));

  more(mainFoot, '/activité/peche-a-pied');
}