/// <reference path="../types/home.ts"/>

import more from '../more';
import bloc from './bloc';
import donut from '../donut';

export default function (container: HTMLElement, species: Home.Species) {
  const main = bloc(container, 'Principales Espèces', 'main');
  let icon = document.createElement('div');
  icon.className = 'icon cup';
  main.appendChild(icon);
  species.principales.forEach(function (item) {
    const results = item.split('/');
    const a = document.createElement('a');
    a.href = '/espèces/' + (results[1] || results[0]);
    a.appendChild(document.createTextNode(results[0]));
    main.appendChild(a);
  });

  const formatter = (v: number) => Math.round(v).toLocaleString('fr-FR');

  const structure = bloc(container, null, 'structure');
  let text = document.createElement('div');
  text.appendChild(document.createTextNode('Les captures normandes'));
  structure.appendChild(text);
  text = document.createElement('div');
  const span = document.createElement('span');
  span.appendChild(document.createTextNode(`${formatter(species.tonnage.normandie)} tonnes`));
  span.className = 'value';
  text.appendChild(span);
  text.appendChild(document.createTextNode(' représentent'));
  structure.appendChild(text);

  const icons: {[id: string]: string} = {
    'raies et requins': 'v1576143311/design/icons/raies_et_requins_gcgldg',
    'céphalopodes': 'v1576143309/design/icons/c%C3%A9phalopodes_yvoird',
    'poissons': 'v1576143309/design/icons/poissons_pc2zp9',
    'coquillages': 'v1576143309/design/icons/coquillages_uzrz02',
    'crustacés': 'v1576143309/design/icons/crustac%C3%A9s_qysvfo'
  }
  let data = species.structure;
  data.forEach(item => item.push('https://res.cloudinary.com/dunxicizc/image/upload/' + icons[item[0]] + '.svg'));
  let sum = data.reduce((acc, item) => acc + item[1], 0);
  data.forEach(item => item[0] = item[0] + ' ' + Math.round(100 * item[1] / sum) + '%');
  let serie1: Charts.Serie<Charts.PieEntry> = { name: 'structure', data, yUnit: 't', color: 'cadetblue', format: _ => '' };
  donut({
    container: structure,
    size: { width: 220, height: 230 },
    serie1,
    margin: { top: 15, right: 0, bottom: 15, left: 0 },
    sort: null
  },
    null, null,
    { width: 30, height: 30, radiusFactor: 1.1 }
  );

  text = document.createElement('div');
  text.appendChild(document.createTextNode('de la production française'));
  structure.appendChild(text);
  text = document.createElement('div');
  text.appendChild(document.createTextNode(`${Math.round(100 * species.tonnage.normandie / species.tonnage.france)} %`));
  text.className = 'percent';
  structure.appendChild(text);
  icon = document.createElement('div');
  icon.className = 'icon boat';
  structure.appendChild(icon);

  more(structure, '/espèces/présentation');

  const production = bloc(container, null, 'production');
  text = document.createElement('div');
  text.appendChild(document.createTextNode('La production normande'));
  production.appendChild(text);
  text = document.createElement('div');
  text.appendChild(document.createTextNode(`${formatter(species.valeur.normandie)} €`));
  text.className = 'value';
  production.appendChild(text);
  text = document.createElement('div');
  text.appendChild(document.createTextNode('représente'));
  production.appendChild(text);

  data = [['Hors Normandie', species.valeur.france - species.valeur.normandie], ['Normandie', species.valeur.normandie]];
  data.forEach(item => item[0] = item[0] + ' ' + Math.round(100 * item[1] / species.valeur.france) + '%');
  serie1 = { name: 'production', data, yUnit: '€', color: 'cadetblue', format: _ => '' };
  donut({
    container: production,
    size: { width: 170, height: 170 },
    serie1,
    margin: { top: 20, right: 10, bottom: 20, left: 10 }
  });
  text = document.createElement('div');
  text.appendChild(document.createTextNode('de la production française'));
  production.appendChild(text);
  text = document.createElement('div');
  text.appendChild(document.createTextNode(`${Math.round(100 * species.valeur.normandie / species.valeur.france)} %`));
  text.className = 'percent';
  production.appendChild(text);
  icon = document.createElement('div');
  icon.className = 'icon euro';
  production.appendChild(icon);
}