/// <reference path="../types/home.ts"/>

import more from '../more';
import blocWithEntries from './blocWithEntries';

export default function (container: HTMLElement, sector: Home.Sector) {
  let entries: Array<[string, string | number, string, string]> = [['marins', sector.marins, null, 'marins'],
  ['pap', sector.pap, null, 'pêcheurs à pied'],
  ['auctions', sector.auctions, null, 'criées'],
  ['fishmongers', sector.fishmongers, null, 'mareyeurs'],
  ['fishshops', sector.fishshops, null, 'poissonneries']];
  const bloc = blocWithEntries(container, 'Chiffres clé', 'sailor', entries);

  more(bloc, '/filière');
}