import * as d3 from 'd3';

type Draw = (container: Charts.d3Selection, color: d3.ScaleOrdinal<string, string> | (() => string), input: Charts.Pie, mouseover?: Charts.MouseCallback, mouseout?: Charts.MouseCallback) => Update;
type Update = (x: number, y: number, innerRadius: number, outerRadius: number, image?: { width: number, height: number, radiusFactor: number }) => void;

const draw: Draw = function (container, color, input, mouseover, mouseout) {
  const arc = d3.arc();

  const data = input.serie1.data;
  const images = data && data.length && data[0].length === 3;

  const pie = d3.pie<[string, number, string?]>().value((d: [string, number]) => d[1]);
  const total = data.reduce((acc, d) => acc + d[1], 0);
  const arcs = (input.sort !== undefined ? pie.sort(null) : pie)(data);

  const g = container.append('g')
    .attr('class', 'arc')
    .attr('font-family', 'sans-serif')
    .attr('font-size', 10)
    .attr('cursor', 'pointer')
    .attr('text-anchor', 'middle');

  g.selectAll('path')
    .data(arcs)
    .enter()
    .append('path');

  g.selectAll('text')
    .data(arcs)
    .join('text')
    .text(d => input.serie1.format ? input.serie1.format(d.data[1]) : d.data[1])
    .attr('dominant-baseline', 'central');

  let maxTextWidth = 0;
  g.selectAll('text').call(function (texts) {
    const nodes = texts.nodes() as Array<SVGTextElement>;
    maxTextWidth = Math.max(...nodes.map(node => node.getBBox().width));
  });

  if (images) {
    g.selectAll('icon')
      .data(arcs)
      .join(function (enter) {
        const g = enter.append('g');
        g.attr("class", "icon");
        g.append('svg:image');
        //        g.append('text');
        return g;
      });
  };

  const update: Update = function (x, y, innerRadius, outerRadius, image) {
    g.attr('transform', `translate(${x}, ${y})`);

    g.selectAll('path')
      .data(arcs)
      .attr('d', d => arc({ innerRadius, outerRadius, startAngle: d.startAngle, endAngle: d.endAngle }))
      .attr('fill', d => color(d.data[0]));

    if (mouseover)
      g.selectAll('path')
        .on('mouseover', (d: d3.PieArcDatum<[string, number, string?]>) =>
          mouseover(d.data, input.size ? input.size.width / 2: 30, 30))
        .on('mouseout', _ => mouseout());

    const noText = maxTextWidth > (arcs.length === 1 ? 2 : .75) * outerRadius;
    g.selectAll('text')
      .data(arcs)
      .text(d => noText || d.data[1] < .05 * total ? '' : input.serie1.format ? input.serie1.format(d.data[1]) : d.data[1])
      .attr('transform', d => {
        return arcs.length === 1 ?
          'translate(0, 0)' :
          `translate(${arc.centroid({ innerRadius: (innerRadius + outerRadius) / 3, outerRadius, startAngle: d.startAngle, endAngle: d.endAngle })})`
      });

    if (images) {
      const x = function (d: d3.PieArcDatum<[string, number, string?]>) {
        const small = d.endAngle - d.startAngle < Math.PI / 5;
        const angle = small ? ((d.index % 2 ? d.startAngle : d.endAngle) - Math.PI / 2) : ((d.startAngle + d.endAngle - Math.PI) / 2);
        let x = image.radiusFactor * outerRadius * Math.cos(angle);
        if (x < 0) x -= image.width;
        return x;
      };
      const y = function (d: d3.PieArcDatum<[string, number, string?]>) {
        const small = d.endAngle - d.startAngle < Math.PI / 5;
        const angle = small ? ((d.index % 2 ? d.startAngle : d.endAngle) - Math.PI / 2) : ((d.startAngle + d.endAngle - Math.PI) / 2);
        let y = image.radiusFactor * outerRadius * Math.sin(angle);
        if (y < 0) y -= image.height;
        return y;
      };
      g.selectAll('.icon image')
        .data(arcs)
        .attr("xlink:href", d => d.data[2])
        .attr("width", image.width)
        .attr("height", image.height)
        .attr("x", x)
        .attr("y", y);

      //       g.selectAll('.icon text')
      //         .data(arcs)
      //         .attr("xlink:href", d => d.data[2])
      //         .attr("x", function (d) {
      //           return x(d) + image.width / 2;
      //         })
      //         .attr("y", function (d) {
      //           const small = d.endAngle - d.startAngle < Math.PI / 5;
      //           return y(d) + (small && d.index % 2 == 0 ? 0 : image.height);
      //         })
      // //        .attr('dominant-baseline', 'central')
      //         .attr('fill', '#777777')
      //         .attr('font-size', '10px')
      //         .text(d => d.data[0]);

    }
  }

  return update;
};

export default draw;